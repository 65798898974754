<template lang="pug">
    div(class="ir-menu-regular" style="width:220px; border:0px solid;")
        v-navigation-drawer(permanent class="menu-drawer")
            //- listed company menu
            template(v-if="getIsListed")
                div(@click="activeOption = 0, openRouter(`/`)" :class="(activeOption === 0) ? 'ir-menu-drawer-active-regular' : ''" class="ir-menu-drawer-hover-regular pa-2" style="border:0px solid;") {{ $t('menu_overview') }}
                div(@click="activeOption = 1, openRouter(`/investInUs`)" :class="(activeOption === 1) ? 'ir-menu-drawer-active-regular' : ''" class="ir-menu-drawer-hover-regular pa-2" style="border:0px solid;") {{ $t('menu_why_invest_in_us') }}
                div(@click="activeOption = 2, openRouter(`/leadershipTeam`)" :class="(activeOption === 2) ? 'ir-menu-drawer-active-regular' : ''" class="ir-menu-drawer-hover-regular pa-2" style="border:0px solid;") {{ $t('menu_leadership_and_board') }}
                div(@click="activeOption = 3, openRouter(`/pressReleasesAndNews`)" :class="(activeOption === 3) ? 'ir-menu-drawer-active-regular' : ''" id="pressReleasesElement" class="ir-menu-drawer-hover-regular pa-2" style="border:0px solid;") {{ $t('menu_press_releases_and_news') }}
                div(@click="activeOption = 4, openRouter(`/earningsAndReports`)" :class="(activeOption === 4) ? 'ir-menu-drawer-active-regular' : ''" class="ir-menu-drawer-hover-regular pa-2" style="border:0px solid;") {{ $t('menu_reports_and_media') }}
                div(@click="activeOption = 5, openRouter(`/companyCalendar`)" :class="(activeOption === 5) ? 'ir-menu-drawer-active-regular' : ''" class="ir-menu-drawer-hover-regular pa-2" style="border:0px solid;") {{ $t('menu_company_calendar') }}
                div(@click="activeOption = 6, openRouter(`/corporate`)" :class="(activeOption === 6) ? 'ir-menu-drawer-active-regular' : ''" class="ir-menu-drawer-hover-regular pa-2" style="border:0px solid;") {{ $t('menu_corporate') }}

            //- unlisted company menu
            template(v-else)
                div(@click="activeOption = 0, openRouter(`/`)" :class="(activeOption === 0) ? 'ir-menu-drawer-active-regular' : ''" class="ir-menu-drawer-hover-regular pa-2" style="border:0px solid;") {{ $t('menu_overview') }}
                div(@click="activeOption = 1, openRouter(`/investInUs`)" :class="(activeOption === 1) ? 'ir-menu-drawer-active-regular' : ''" class="ir-menu-drawer-hover-regular pa-2" style="border:0px solid;") {{ $t('menu_why_invest_in_us') }}
                div(@click="activeOption = 2, openRouter(`/leadershipTeam`)" :class="(activeOption === 2) ? 'ir-menu-drawer-active-regular' : ''" class="ir-menu-drawer-hover-regular pa-2" style="border:0px solid;") {{ $t('ul_menu_management_and_board') }}
                div(@click="activeOption = 3, openRouter(`/companyCalendar`)" :class="(activeOption === 3) ? 'ir-menu-drawer-active-regular' : ''" class="ir-menu-drawer-hover-regular pa-2" style="border:0px solid;") {{ $t('menu_company_calendar') }}
                div(@click="activeOption = 4, openRouter(`/corporate`)" :class="(activeOption === 4) ? 'ir-menu-drawer-active-regular' : ''" class="ir-menu-drawer-hover-regular pa-2" style="border:0px solid;") {{ $t('menu_corporate') }}

            //- listing of generic pages
            div(v-for="(page, index) in pages" :key="index" @click="activeOption = (7 + index), openRouter(`/genericComponent/${page.page_id}`)" :id="`genericMenuElement${page.page_id}`" :class="(activeOption === (7 + index)) ? 'ir-menu-drawer-active-regular' : ''" class="ir-menu-drawer-hover-regular pa-2" style="border:0px solid;") {{ page.menu_display }}
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "Menu",
    data() {
        return {
            activeOption: 0,
        };
    },
    mounted() {
        console.log('pages', JSON.parse(JSON.stringify(this.pages)));
    },
    computed: {
        ...mapGetters([
            'getCompany',
            'getIsListed'
        ]),
        pages() {
            return this.getCompany.pages;
        },
    },
    methods: {
        openRouter(router) {
            const routerTemp = (router !== '') ? router : '/';

            this.$router.push({ path: routerTemp }).catch(() => {});
        }
    }
};
</script>

<style scoped>
.menu-drawer >>> .v-navigation-drawer__border {
    display: none;
}
</style>
